import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const AmenitiesSection1 = ({
  headingLevel,
  className,
  sectionTitle,
  sectionDesc,
}) => {
  return (
    <>
      <section className="relative mb-20 bg-tertiary-50 pt-12 md:mb-32 md:py-32 lg:py-44">
        <div className="absolute bottom-0 left-0 hidden h-full w-full md:block">
          <StaticImage
            src="../../images/5.0 Amenities/1.0 Amenities-Hero.jpg"
            loading="eager"
            className="h-full"
            imgClassName="object-top"
          />
        </div>

        <div className="container relative">
          <div className="grid gap-y-12">
            <div className="text-center md:max-w-[560px] md:text-left">
              <h1 className=" md:text-white">{sectionTitle}</h1>
              <p className="md:text-white">{sectionDesc}</p>
              <ButtonSolid
                className="hidden md:block"
                modal="modal-contact"
                altStyle={2}
                text="Contact Us"
              />
              <ButtonSolid
                className="block md:hidden"
                modal="modal-contact"
                altStyle={1}
                text="Contact Us"
              />
            </div>
            <div className="-mx-4 md:mx-0 md:hidden">
              <StaticImage
                src="../../images/5.0 Amenities/1.0 Amenities-mobile.jpg"
                loading="eager"
                className="h-full w-full"
                imgClassName="object-top"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmenitiesSection1;
