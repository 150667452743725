import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const AmenitiesSection4 = ({
  headingLevel,
  className,
  title1,
  description1,
  title2,
  description2,
  title3,
  description3,
}) => {
  return (
    <>
      <section className={` ${className || "mb-16 md:mb-24"}`}>
        <div className="container">
          <div className="space-y-20 md:space-y-24">
            <div>
              {title1 && <h2>{title1}</h2>}
              <p>
                RanchView Senior Assisted Living is nestled in an unincorporated
                area called Olivenhain, which is between Encinitas and Rancho
                Santa Fe. Our property includes:
              </p>
              <div className="grid grid-flow-row auto-rows-max grid-cols-1 sm:grid-cols-1">
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>A large, 15,000 square-foot home</p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>
                    Private and semi-private rooms that can be decorated by the
                    family or a professional decorator
                  </p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>
                  Spacious outdoor patio area great for visits, outdoor activities and live music
                  </p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Lush landscaping</p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Hydration stations throughout the community</p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Non-smoking buildings</p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid items-center gap-y-10 pt-10 md:grid-cols-1 md:gap-x-10 lg:gap-x-20">
            <div>
              {title2 && <h2>{title2}</h2>}
              <p>
                RanchView’s staff is carefully screened and hired to serve
                residents with integrity, compassion, and dedication to their
                safety and comfort. Our team includes:{" "}
              </p>
              <div className="grid grid-flow-row auto-rows-max grid-cols-1 sm:grid-cols-1">
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>
                    Highly trained Memory Care Team with expert knowledge in
                    caring for those with Alzheimer’s disease and other types of
                    dementia
                  </p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>
                  Mobile physicians and on call nursing 
                  </p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Certified Nursing Assistants (CNAs), Medical Assistance (MA), and Trained caregivers  </p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Access to medical and mental health professionals</p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>
                    On-site professional culinary staff that prepares gourmet
                    meals and refreshments with fresh, organic ingredients
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="grid items-center gap-y-10 pt-10 md:grid-cols-1 md:gap-x-10 lg:gap-x-20">
            <div>
              {title3 && <h2>{title3}</h2>}
              <p>
                RanchView is a safe environment for both our staff and
                residents. Our safety and security measures include:
              </p>
              <div className="grid grid-flow-row auto-rows-max grid-cols-1 sm:grid-cols-1">
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Secured perimeter with gated access</p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Access to 24-hour emergency services </p>
                </div>
                
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Access to safe areas and grounds throughout the property </p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>Surveillance cameras in all common and outside areas</p>
                </div>
                <div className="flex">
                  <StaticImage
                    className="mr-2.5 flex-none"
                    src="../../images/5.0 Amenities/check.svg"
                    alt="check"
                    layout="fixed"
                    width={24}
                    height={24}
                    loading="eager"
                    placeholder="none"
                  />
                  <p>
                    Building meets all ADA requirements including wide hallways,
                    safety rails, shower chairs, and no lips or gaps at doorways
                    &#40;for easy wheelchair and walker access&#41;
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AmenitiesSection4;
